import React from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <header className="privacy-header">
        <h1>Privacy Policy</h1>
        <p>Effective Date: [Insert Date]</p>
      </header>

      <section className="privacy-content">
        <p>
          At <strong>FindYourPandit.com</strong>, we are committed to protecting your personal
          information and respecting your privacy. This Privacy Policy explains how we collect,
          use, and safeguard your personal data when you use our website to book online pooja
          services and Panditji.
        </p>
        <p>
          By accessing and using <strong>FindYourPandit.com</strong>, you agree to the practices outlined in this policy. If you do not agree with this policy, please do not use our website.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect the following types of information from you when you use our website:
        </p>
        <ul>
          <li>Personal Information: Name, email address, phone number, address, and other details.</li>
          <li>Booking Information: Details of services you book, such as pooja type, date, etc.</li>
          <li>Payment Information: Credit/debit card details and payment transactions (secure).</li>
          <li>Automatically Collected: IP address, browser type, device info, cookies, and usage data.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Process and confirm your bookings.</li>
          <li>Send you confirmations, updates, and promotions (if opted in).</li>
          <li>Securely process payments for services.</li>
          <li>Improve our website functionality and service quality.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell or rent your data. However, we may share information with trusted
          third-party service providers for payment processing or legal obligations.
        </p>

        <h2>4. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies to improve your experience. You can refuse cookies through browser settings,
          but this may affect your experience on our website.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We use industry-standard security measures like SSL encryption to protect your data.
          However, no method is 100% secure, so we cannot guarantee absolute protection.
        </p>

        <h2>6. Your Rights</h2>
        <p>You have the right to access, correct, or delete your data. Contact us to exercise these rights.</p>

        <h2>7. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Please check this page periodically for
          updates.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions, please contact us at <a href="mailto:support@findyourpandit.com">support@findyourpandit.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
