import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import '../styles/panditjistyle.css';
import '../styles/header.css';
import panditjiImage from '../assets/images/worship.jpg';
import FeaturedServices from './FeaturedServices.js';
import Testimonial from './Testimonial.js';
import { useNavigate } from 'react-router-dom';
import TokenValidator from './TokenValidator.js';
import poojaOptions from '../data/PoojaOptions.js';
import {backendurl} from './Useurl.js';


const FindMyPanditji = () => {
    const url=backendurl;
    const [showPopup, setShowPopup] = useState(false);
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [panditJis, setPanditJis] = useState([]);
    const [isPanditJiVisible, setIsPanditJiVisible] = useState(false);
    const [showpanditjiinfoPopup, setshowpanditjiinfoPopup] = useState(false);
    const [showBookingPopup, setShowBookingPopup] = useState(false);
    const [selectedPanditji, setSelectedPanditji] = useState(null);
    const [bookingDetails, setBookingDetails] = useState({
      userName: '',
      address: '',
      date: '',
      time: '',
      poojaType: '',
      poojanSamagri: false,
      mobilenumber: '',
    });
    const [mobileNumberError, setMobileNumberError] = useState('');
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);
    const usermobileNumber = sessionStorage.getItem('mobile_number');
    const email = sessionStorage.getItem('email');
    console.log(email)
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3, // Adjust as needed for your design
        slidesToScroll: 1,
        arrows: true,
        centerMode: false,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3, // Adjust based on card width
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2, // Show 2 cards per row
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1, // Show 1 card per row
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    
    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await fetch(`${url}/services/get_unique_cities/`);
                const data = await response.json();
                setCities(data);
                console.log('Cities loaded:', data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities();
    }, []);

    useEffect(() => {
        const fetchAreas = async () => {
            if (selectedCity) {
                try {
                    const response = await fetch(`${url}/services/get_unique_areas/?city=${encodeURIComponent(selectedCity)}`);
                    const data = await response.json();
                    setAreas(data);
                    console.log('Areas loaded:', data);
                } catch (error) {
                    console.error('Error fetching areas:', error);
                }
            } else {
                setAreas([]);
            }
        };

        fetchAreas();
    }, [selectedCity]);

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setSelectedArea('');
    };

    const handleFindPanditJi = async () => {
        try {
            const response = await fetch(`${url}/services/find_panditji_city/?city=${encodeURIComponent(selectedCity)}`);
            const data = await response.json();
            setPanditJis(data);
            setIsPanditJiVisible(true);
            console.log('Pandit Jis loaded:', data);
        } catch (error) {
            console.error('Error fetching Pandit Jis:', error);
        }
    };
    const handleFindPanditJiinarea = async () => {
        try {
            const response = await fetch(`${url}/services/find_panditji_area/?city=${encodeURIComponent(selectedCity)}&area=${encodeURIComponent(selectedArea)}`);
            const data = await response.json();
            setPanditJis(data);
            setIsPanditJiVisible(true);
            console.log('Pandit Jis loaded:', data);
        } catch (error) {
            console.error('Error fetching Pandit Jis:', error);
        }
    };

    useEffect(() => {
        if (selectedArea) {
            handleFindPanditJiinarea();
        }
    }, [selectedArea]);

    useEffect(() => {
        const fetchBookings = async () => {
            if (usermobileNumber) {
                try {
                    const response = await axios.get(`${url}/api/user-bookings/`, {
                        params: { mobile_number: usermobileNumber }
                    });
                    if (response.data.success) {
                        setBookings(response.data.bookings);
                    } else {
                        setError(response.data.message);
                    }
                } catch (error) {
                    setError('Error fetching bookings');
                    console.error('Error fetching bookings:', error);
                }
            } else {
                setError('Mobile number is missing');
            }
        };

        fetchBookings();
    }, [usermobileNumber]);

    const handleareafilter = (event) => {
        setSelectedArea(event.target.value);
    };

    const handleInfoClick = (panditji) => {
        setSelectedPanditji(panditji);
        setshowpanditjiinfoPopup(true);
    };

    const handleBookingClick = (panditji) => {
        setSelectedPanditji(panditji);
        setShowBookingPopup(true);
    };

    const handleBookingFormSubmit = async (event) => {
      event.preventDefault();
      console.log('Booking form submission initiated');
  
      // Get CSRF token from cookies
      const csrftoken = getCookie('csrftoken');
      const mobilenumber = sessionStorage.getItem('mobile_number')
    if (!mobilenumber.startsWith('+91')){
        setMobileNumberError('User`s mobile number is Invalid');
        return; // Stop form submission if validation fails
          }

        setMobileNumberError(''); // Clear error if validation passes
      try {
          const response = await fetch(`${url}/services/book_panditji/`, {
              method: 'POST',
              headers: {
                  'X-CSRFToken': csrftoken,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  userName: sessionStorage.getItem('username'),
                  address: bookingDetails.address,
                  date: bookingDetails.date,
                  time: bookingDetails.time,
                  duration:bookingDetails.duration,
                  poojaType: bookingDetails.poojaType,
                  poojanSamagri: bookingDetails.poojanSamagri,
                  panditji: selectedPanditji.username,
                  mobilenumber:usermobileNumber,
                  email:email
              }),
          });
  
          const data = await response.json();
          if (data.status === 'success') {
              alert(data.message);
              setShowBookingPopup(false);
              console.log('Booking successful');
          } else if(data.status === 'error' && data.message === 'Booking already exists'){
            alert('Booking already exists');
            console.log('Booking already exists');
          }else{
            alert(data.message);
          }
      } catch (error) {
        console.error('Error:', error);
        alert('An unexpected error occurred');
    }
  };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

     const handlePoojaTypeChange = (e) => {
        const selectedType = e.target.value;
        const selectedPooja = poojaOptions.find(pooja => pooja.type === selectedType);
        setBookingDetails({
            ...bookingDetails,
            poojaType: selectedType,
            duration: selectedPooja ? selectedPooja.duration : '',
            cost: selectedPooja ? selectedPooja.cost : ''
        });
    };

    // Function to get CSRF token from cookies
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <div>
            <TokenValidator />
            <main>
                <section className="hero">
                    <div className="container">
                    <h1>Vedic Science at the Heart of Our Rituals</h1>
                    <h1>Bringing Depth and Meaning to Every Pooja</h1>
                    <p className="glowing-text">Book expert Pandit Ji services online for all your religious ceremonies.</p>
                        <div className="search-bar">
                            <select onChange={handleCityChange} value={selectedCity}>
                                <option value="">Select City</option>
                                {cities.map((city, index) => (
                                    <option key={index} value={city}>{city}</option>
                                ))}
                            </select>
                            <button onClick={handleFindPanditJi}>Find Pandit Ji</button>
                        </div>
                    </div>
                </section>
                {isPanditJiVisible && (
                    <section className="available-panditji">
                        <div className="panditjicontainer">
                            <h2>Available Panditji In Your City</h2>
                            <div className="search-bar">
                            <select onChange={handleareafilter} value={selectedArea} disabled={!areas.length}>
                                <option value="">Select Area</option>
                                
                                {areas.map((area, index) => (
                                    <option key={index} value={area}>{area}</option>
                                ))}
                                 </select>
                                 </div>
                            {panditJis.length > 0 ? (
                                <div className="panditji-container">
                                    {panditJis.map((panditji, index) => (
                                        <div key={index} className="panditji-box"> 
                                        <img 
                                            src={panditji.document_url} 
                                            alt="Panditji Photo Not Available"
                                            onError={(e) => { 
                                                e.target.onerror = null; 
                                                e.target.src = panditji.default_document_url; // Remove curly braces here
                                            }} 
                                        />  
                                            <h3>{panditji.first_name} {panditji.last_name}</h3>
                                            <p class="text-center">Panditji has {panditji.experience} years of experience.</p>
                                            <div className="options">
                                                {/* <button onClick={() => handleInfoClick(panditji)}>Info</button> */}
                                                <button onClick={() => handleBookingClick(panditji)}>Booking</button>
                                             </div>                                           
                           
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No Pandit Ji found for the selected city and area.</p>
                            )}
                        </div>
                    </section>
                )}{showBookingPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <span className="close" onClick={() => setShowBookingPopup(false)}>&times;</span>
                            <form onSubmit={handleBookingFormSubmit}>
            <label>
                Address:
                <input
                    type="text"
                    name="address"
                    value={bookingDetails.address}
                    onChange={handleInputChange}
                    placeholder="Please provide the address"
                    required
                />
            </label>

            <label>
                Date:
                <input
                    type="date"
                    name="date"
                    value={bookingDetails.date}
                    onChange={handleInputChange}
                    min={getCurrentDate()}
                    required
                />
            </label>

            <label>
                Time:
                <input
                    type="time"
                    name="time"
                    value={bookingDetails.time}
                    onChange={handleInputChange}
                    required
                />
            </label>

            <label>
                Type of Pooja:
                <select
                    type="select"
                    name="poojaType"
                    value={bookingDetails.poojaType}
                    onChange={handlePoojaTypeChange}
                    required
                >
                    <option value="">Select a Pooja</option>
                    {poojaOptions.map((pooja, index) => (
                        <option key={index} value={pooja.type}>
                            {pooja.type}
                        </option>
                    ))}
                </select>
            </label>

            <label>
                Duration (hours):
                <input
                    type="number"
                    name="duration"
                    value={bookingDetails.duration}
                    onChange={handleInputChange}
                    readOnly
                    placeholder="Duration (hours)"
                />
            </label>

            <label>
                Cost:
                <input
                    type="text"
                    name="cost"
                    value={bookingDetails.cost}
                    onChange={handleInputChange}
                    readOnly
                    placeholder="Cost"
                />
            </label>

            <label>
                Poojan Samagri:
                <input
                    type="checkbox"
                    name="poojanSamagri"
                    checked={bookingDetails.poojanSamagri}
                    onChange={handleInputChange}
                />
            </label>

            <button type="submit">Submit</button>
            {mobileNumberError && <p style={{ color: 'red' }}>{mobileNumberError}</p>}
        </form>
                        </div>
                    </div>
                )}
                {/* <section className="booking-container"> */}
                    <div className="bookings">
                        <h1>Your Poojan Bookings</h1>
                        {error && <p className="error-message">{error}</p>}
                        {bookings.length > 0 ? (
                            <Slider {...settings}>
                                {bookings.map((booking, index) => (
                                    <div className="booking-card" key={index}>
                                        <h3><strong>Booking With :</strong> {booking.panditji}</h3>
                                        <p><strong>Date:</strong> {booking.date}</p>
                                        <p><strong>Time:</strong> {booking.time}</p>
                                        <p><strong>Address:</strong> {booking.address}</p>
                                        <p><strong>Pooja Type:</strong> {booking.pooja_type}</p>
                                        <p><strong>Duration:</strong> {booking.duration_hours} Hours</p>
                                        <p><strong>Samagri:</strong> {booking.poojan_samagri ? 'Yes' : 'No'}</p>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <p>No bookings available</p>
                        )}
                        </div>
                {/* </section> */}
                <FeaturedServices />
 
             
                    <Testimonial />
            </main>
            
        </div>
    );
};

export default FindMyPanditji;
