import react, { useEffect } from "react";
import '../styles/Howitworks.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import map from '../assets/images/Location.jpg';
import panditji from '../assets/images/panditji.jpg';
import calender from '../assets/images/calender.jpg';
import worship from '../assets/images/worship.jpg';
const Howitworks = () =>{
    const settings = {
        slidesToShow: 3,  // Default number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Adjust for tablets and smaller screens
        settings: {
          slidesToShow: 1, // Show only one slide at a time
          slidesToScroll: 1,
          arrows: false,  // Optional: hide arrows on mobile
          dots: true  // Optional: show dots for navigation on mobile
        }
      }
    ]
    };
    return(
        <section className="how-it-works">
        <div className="container">
            <h2>How It Works</h2>
            <div className="steps">
        <Slider {...settings}>
                <div className="step">
                    <img src={map} alt="Choose City and Area" />
                    <h3>Choose Location & Pooja</h3>
                </div>
                <div className="step">
                    <img src={panditji} alt="Select Pandit Ji as per Your need." />
                    <h3>Select Pandit Ji</h3>
                </div>
                <div className="step">
                    <img src={calender} alt="Schedule & Book" />
                    <h3>Schedule & Book</h3>
                </div>
                <div className="step">
                    <img src={worship} alt="Enjoy Pooja" />
                    <h3>Enjoy Pooja</h3>
                </div>
            </Slider>
            </div>
        </div>
    </section>
    );
}
export default Howitworks;
