import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import PanditjiDashboard from './components/PanditjiDashboard';
import FindMyPanditji from './components/FindMyPanditji';
import './styles/App.css';
import Footer from './components/Footer';
import './styles/header.css';
import './styles/footer.css';
import './styles/panditjistyle.css';
import './styles/maidstyles.css';
import Register from './components/Register';
import Login from './components/Login';
import Logout from './components/Logout';
import HowWeAreDifferent from './components/HowWeAreDifferent';
import PrivacyPolicy  from './components/PrivacyPolicy';
import TermsOfServices from './components/TermsOfServices';

const isAuthenticated = () => {
  const token = sessionStorage.getItem('access_token');
  return !!token;
};

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsofservices" element={<TermsOfServices />} />
          <Route path="/findMyPanditji" element={isAuthenticated() ? <FindMyPanditji /> : <Navigate to="/login" />} />
          <Route path="/logout" element={isAuthenticated() ? <Logout /> : <Navigate to="/login" />} />
          <Route path="/PanditjiDashboard" element={isAuthenticated() ? <PanditjiDashboard /> : <Navigate to="/login" />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/Howwearedifferent" element={<HowWeAreDifferent />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;

