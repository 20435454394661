import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {frontendurl,backendurl} from './Useurl.js';

const TokenValidator = () => {
    const url=backendurl;
    const internalurl=frontendurl;
    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');

        if (!token) {
            alert("You need to be logged in to visit this page, navigating back to login");
            window.location.href=`${internalurl}/logout`; // Redirect to logout or login
        } else {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            axios.post(`${url}/api/validate-token/`)
                .then(response => {
                    if (!response.data.success) {
                        sessionStorage.removeItem('access_token');
                        alert("Your session is not valid anymore, please login again.");
                        window.location.href=`${internalurl}/logout`;
                    }
                })
                .catch(error => {
                    console.error('Token validation error:', error);
                    sessionStorage.removeItem('access_token');
                    alert("Error validating token, navigating back to login");
                    window.location.href=`${internalurl}/logout`;
                });
        }
    }, [navigate]);

    return null; // This component doesn't render anything
};

export default TokenValidator;
