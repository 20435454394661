import React from 'react';
import '../styles/TermsOfServices.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Terms and Conditions</h1>
        <p>Effective Date: [Insert Date]</p>
      </header>

      <section className="terms-content">
        <p>
          Welcome to <strong>FindYourPandit.com</strong>! These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our website.
        </p>

        <h2>1. Definitions</h2>
        <p>
          <strong>"Service"</strong> refers to the online pooja booking services provided by <strong>FindYourPandit.com</strong>.<br />
          <strong>"User"</strong>, <strong>"You"</strong>, and <strong>"Your"</strong> refer to individuals or entities accessing or using the Service.<br />
          <strong>"We"</strong>, <strong>"Us"</strong>, and <strong>"Our"</strong> refer to <strong>FindYourPandit.com</strong> and its affiliates.
        </p>

        <h2>2. Acceptance of Terms</h2>
        <p>
          By using our Service, you affirm that you are at least 18 years old and capable of entering into legally binding contracts. If you are using the Service on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
        </p>

        <h2>3. Service Description</h2>
        <p>
          <strong>FindYourPandit.com</strong> allows users to book online pooja services and Panditji for various rituals and ceremonies. The specifics of each service, including pricing, are outlined on our website.
        </p>

        <h2>4. User Accounts</h2>
        <p><strong>Registration:</strong> To access certain features, you may need to create an account. You agree to provide accurate, current, and complete information during registration and to update such information to keep it accurate.</p>
        <p><strong>Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        <p><strong>Termination:</strong> We reserve the right to terminate or suspend your account at our discretion, without notice, for conduct that violates these Terms or is otherwise harmful to other users or us.</p>

        <h2>5. Booking and Payments</h2>
        <p><strong>Booking Process:</strong> Users can book services through our website by selecting the desired pooja and Panditji, choosing a suitable date and time, and completing the payment process.</p>
        <p><strong>Payment Terms:</strong> All payments are processed securely through our payment gateway partners. Prices are subject to change without notice.</p>
        <p><strong>Cancellations and Refunds:</strong> Cancellations must be made at least [Insert Timeframe] before the scheduled service to qualify for a refund. Refunds, if applicable, will be processed using the original payment method.</p>

        <h2>6. User Responsibilities</h2>
        <p><strong>Accurate Information:</strong> You agree to provide accurate and complete information when booking services.</p>
        <p><strong>Compliance:</strong> You agree to use the Service in compliance with all applicable laws and regulations.</p>
        <p><strong>Prohibited Activities:</strong> Users must not engage in activities that could harm the Service or other users, including but not limited to:
          <ul>
            <li>Unauthorized access or use of the Service.</li>
            <li>Distributing harmful or offensive content.</li>
            <li>Interfering with the operation of the Service.</li>
          </ul>
        </p>

        <h2>7. Intellectual Property</h2>
        <p>
          All content, trademarks, and intellectual property displayed on the website are the property of <strong>FindYourPandit.com</strong> or their respective owners. You are prohibited from using any of our intellectual property without explicit permission.
        </p>

        <h2>8. Limitation of Liability</h2>
        <p>
          <strong>FindYourPandit.com</strong> is not liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the Service. Our total liability to you for any claims under these Terms is limited to the amount you have paid us for the Service.
        </p>

        <h2>9. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless <strong>FindYourPandit.com</strong>, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of the Service or violation of these Terms.
        </p>

        <h2>10. Governing Law</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles. Any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts located in [Your Jurisdiction].
        </p>

        <h2>11. Changes to Terms</h2>
        <p>
          We may update these Terms from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. Your continued use of the Service after such changes constitutes your acceptance of the new Terms.
        </p>

        <h2>12. Termination</h2>
        <p>
          We reserve the right to terminate or suspend your access to the Service, without prior notice or liability, for any reason, including if you breach these Terms.
        </p>

        <h2>13. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
          <br />
          <strong>Email:</strong> <a href="mailto:support@findyourpandit.com">support@findyourpandit.com</a><br />
          <strong>Phone:</strong> [+918274551974]<br />
          <strong>Address:</strong> [Insert Company Address]
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
