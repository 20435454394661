import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../styles/PanditjiDashboard.css'; // Import the CSS file
import TokenValidator from './TokenValidator.js';
import {frontendurl,backendurl} from './Useurl.js';

const PanditjiDashboard = () => {
    const url=backendurl;
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mobileNumber = sessionStorage.getItem('mobile_number')

    useEffect(() => {
        const fetchBookings = async () => {
            if (mobileNumber) {
                try {
                    const response = await axios.get(`${url}/api/panditji-bookings/`, {
                        params: { mobile_number: mobileNumber }
                    });
                    if (response.data.success) {
                        setBookings(response.data.bookings);
                    } else {
                        setError(response.data.message);
                    }
                } catch (error) {
                    setError('Error fetching bookings');
                    console.error('Error fetching bookings:', error);
                }
            } else {
                setError('Mobile number is missing');
            }
        };

        fetchBookings();
    }, [mobileNumber]);

    return (
        <div>
        <TokenValidator />
        <div className="dashboard-container">
            <h1>Your Upcoming Bookings</h1>
            {error && <p className="error-message">{error}</p>}
            {bookings.length > 0 ? (
                <div className="bookings-list">
                    {bookings.map((booking, index) => (
                        <div className="booking-card" key={index}>
                            <h3>Booking for {booking.user_name}</h3>
                            <p><strong>Date:</strong> {booking.date}</p>
                            <p><strong>Time:</strong> {booking.time}</p>
                            <p><strong>Address:</strong> {booking.address}</p>
                            <p><strong>Pooja Type:</strong> {booking.pooja_type}</p>
                            <p><strong>Duration:</strong> {booking.duration_hours} Hours</p>
                            <p><strong>Samagri:</strong> {booking.poojan_samagri ? 'Yes' : 'No'}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No bookings available</p>
            )}
        </div>
    </div>
    );
};

export default PanditjiDashboard;
