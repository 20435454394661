import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Register.css';
import {frontendurl,backendurl} from './Useurl.js';

const Register = () => {
    const url=backendurl;
    const [username, setUsername] = useState('');
    const [user_type, setUsertype] = useState('normal');
    const [mobile, setMobile] = useState('+91');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const validateEmail = (email) => {
    // Basic regex for email validation
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!validateEmail(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };
    const handleRegister = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any previous error message
        try {
            const response = await axios.post(`${url}/api/register/`, {
                username,                
                mobile_number: mobile,                
                email,
            });
            if (response.status === 201) {
                navigate('/login');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Capture the validation error message from the server
                setErrorMessage(error.response.data.mobile_number || 'Registration not successfull.Please refresh the page and try again.');
            } else {
                setErrorMessage('Registration not successfull.Please refresh the page and try again.');
            }
        }
    };

    return (

            <div className="register-container">
                <div className="register-card">
                    <h1 className="register-title">Register</h1>
                    <input
                        type="text"
                        className="register-input"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Full Name"
                    />
                    
                    <input
                        type="text"
                        className="register-input"
                        value={mobile}
                        onChange={(e) => {
                            let value = e.target.value;
                        
                            // Retain +91 and allow only digits (0-9) afterward
                            if (value.startsWith('+91')) {
                              if (/^\+91[0-9]{0,10}$/.test(value)) {
                                setMobile(value);
                              }
                            } else {
                              // If the user tries to delete +91, reset it back
                              setMobile('+91');
                            }
                          }}
                          maxLength="13"
                          inputMode="numeric" // Helps on mobile devices to show numeric keypad
                        />
                     <input
                        type="text"
                        className="register-input"
                        value={email}
                        onChange={handleChange}
                        placeholder="Email"
                    />
                    {emailError && <p className="error-message">{emailError}</p>}                    
                   
            {errorMessage && <p >{errorMessage}</p>}
                    <button className="register-button" onClick={handleRegister}>
                        Register
                    </button>
                </div>
                
            </div>
            

    );
};

export default Register;
