// src/components/FeaturedServices.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Featuredservices.css';
import ganesh from '../assets/images/ganeshpoojan.jpg';
import grihpravesh from '../assets/images/grihpravesh.jpg';
import grahshanti from '../assets/images/grahshanti.jpg';
import satyaranayan from '../assets/images/satyanarayanjipooja.png';
import lakshmiji from '../assets/images/lakshmipoojan.jpg';
import rudrabhishek from '../assets/images/rudrabhishekpoojan.jpg';
import navgrah from '../assets/images/navgrahpoojan.jpg'
 

const FeaturedServices = () => {
    const settings = {
        slidesToShow: 3,  // Default number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Adjust for tablets and smaller screens
        settings: {
          slidesToShow: 1, // Show only one slide at a time
          slidesToScroll: 1,
          arrows: false,  // Optional: hide arrows on mobile
          dots: true  // Optional: show dots for navigation on mobile
        }
      }
    ]
    };

    return (
        <section className="featured-services">
            <div className="container">
                <h2>Featured Services</h2>
                <Slider {...settings}>
                    <div className="service">
                        <img src={ganesh} alt="Ganesh Poojan" />
                        <h3>Ganesh Poojan</h3>
                        <p>गणेश के साथ करें शुभारंभ, पाएं अनंत आशीर्वाद।</p>
                    </div>
                    <div className="service">
                        <img src={grahshanti} alt="Griha Shanti / Vastu Shanti" />
                        <h3>Griha Shanti / Vastu Shanti</h3>
                        <p>गृह शांति पूजन: हर संकट का नाश, घर में सुख का निवास।</p>
                    </div>
                    <div className="service">
                        <img src={satyaranayan} alt="Satyanarayan Pooja" />
                        <h3>Satyanarayan Pooja</h3>
                        <p>सत्यनारायण पूजन: भगवान का आशीर्वाद, जीवन में शांति और संतोष।</p>
                    </div>
                    <div className="service">
                        <img src={grihpravesh} alt="Griah Pravesh" />
                        <h3>Grih Pravesh</h3>
                        <p>गृह प्रवेश: नए घर में शुभता और समृद्धि का स्वागत।</p>
                    </div>
                    <div className="service">
                        <img src={lakshmiji} alt="Lakshmi Pooja" />
                        <h3>Lakshmi Pooja</h3>
                        <p>लक्ष्मी पूजन: समृद्धि की देवी का स्वागत, खुशहाल जीवन का आरंभ।</p>
                    </div>
                    <div className="service">
                        <img src={rudrabhishek} alt="Rudrabhishek" />
                        <h3>Rudrabhishek</h3>
                        <p>रुद्राभिषेक पूजन: शिव के अभिषेक से मिलती है अपार शक्ति और शांति।</p>
                    </div>
                    <div className="service">
                        <img src={navgrah} alt="Navgrahan Poojan" />
                        <h3>Navagraha Poojan</h3>
                        <p>नवग्रह पूजा: ग्रहों के आशीर्वाद से जीवन में बने सुख और समृद्धि।</p>
                    </div>
                  
                </Slider>
            </div>
        </section>
    );
};

export default FeaturedServices;
