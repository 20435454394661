import React from 'react';
import '../styles/panditjistyle.css';
import { useNavigate } from 'react-router-dom';
import RegisterPanditji from '../components/PanditjiRegister';
import FeaturedServices from './FeaturedServices';
import Testimonial from './Testimonial';
import WhyUs from './WhyUs';
import Howitworks from './Howitworks';

const Home = () => {
  const navigate = useNavigate();

  // Function to navigate to the 'HowWeAreDifferent.js' page
  const handleClick = () => {
    navigate('/howwearedifferent'); // Route to HowWeAreDifferent.js page
  };

  return (
    <div>
      <RegisterPanditji />
      <main>
        <section className="hero">
          <div className="container">
            <h1>Vedic Science at the Heart of Our Rituals</h1>
            <h1>Bringing Depth and Meaning to Every Pooja</h1>
            <p className="glowing-text">Book expert Pandit Ji services online for all your religious ceremonies.</p>
    <div className="offer-banner" onClick={handleClick}>
          Check Here!! How We Are Different!!
        </div>
          </div>
        </section>

        {/* Offer banner linking to How We Are Different page */}
        

        <WhyUs />

       
        <FeaturedServices />

        <Howitworks />
        
        <Testimonial />
      </main>
    </div>
  );
};

export default Home;
