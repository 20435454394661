import React, { useState } from "react";
import cities from '../data/cities.json'; // Adjust path as needed
import '../styles/RegisterPanditji.css';
import {frontendurl,backendurl} from './Useurl.js';

const RegisterPanditji = () => {
    const url=backendurl;
    const [showPopup, setShowPopup] = useState(false);
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [city, setCity] = useState('');
    const [filteredCities, setFilteredCities] = useState([]);
    const [validCity, setValidCity] = useState(true); // Track if the city input is valid
    const [showCityError, setShowCityError] = useState(false); // Track if city error should be displayed

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // If city is not valid, show an error message and prevent submission
        if (!validCity) {
            setShowCityError(true);
            return;
        }

        const form = event.target;
        const formData = new FormData(form);

        // Validate mobile number
        const mobileNumber = formData.get('mobileNumber');
        if (!mobileNumber.startsWith('+91')) {
            setMobileNumberError('Mobile number must start with +91 or your country code');
            return; // Stop form submission if validation fails
        }

        setMobileNumberError(''); // Clear error if validation passes

        // Get CSRF token from cookies
        const csrftoken = getCookie('csrftoken');

        try {
            const response = await fetch(`${url}/services/register_panditji/`, {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                    'Accept': 'application/json',
                },
                body: formData,
            });

            const data = await response.json();

            if (response.ok && data.success) {
                alert("Pandit Ji registered successfully!");
                form.reset();
                // Reset city and filtered cities
                setCity('');
                setFilteredCities([]);
                setValidCity(true);
                setShowCityError(false);
                setShowPopup(false);
            } else {
                // Handle error based on the error code
                switch (data.error.code) {
                    case 'MISSING_FIELDS':
                        alert('Please fill in all required fields.');
                        break;
                    case 'ALREADY_EXISTS':
                        alert('Pandit Ji with this mobile number already exists.');
                        break;
                    case 'VALIDATION_ERROR':
                        alert('There was a validation error.');
                        break;
                    case 'UNKNOWN_ERROR':
                        alert('An unknown error occurred. Please try again later.');
                        break;
                    default:
                        alert('An unexpected error occurred.');
                }
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred while registering. Please try again later.");
        }
    };

    // Function to get CSRF token from cookies
    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const handleCityChange = (event) => {
        const query = event.target.value;
        setCity(query);

        if (query.length > 0) {
            const filtered = cities.filter(c => c.toLowerCase().includes(query.toLowerCase()));
            setFilteredCities(filtered);
        } else {
            setFilteredCities([]);
        }

        // Check if the query exactly matches one of the cities (case-sensitive)
        setValidCity(cities.includes(query));
    };

    const handleSuggestionClick = (suggestion) => {
        setCity(suggestion);
        setFilteredCities([]);
        setValidCity(true);
        setShowCityError(false); // Hide error when a valid suggestion is selected
    };

    const handleBlur = () => {
        // Check if the city input is valid when it loses focus
        setValidCity(cities.includes(city));
    };

    return (
        <div>
            <button id="registerPanditjiBtn" onClick={() => setShowPopup(true)}>Register Panditji</button>
            {showPopup && (
                <div id="panditjiPopup" className="popup" onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        setShowPopup(false);
                    }
                }}>
                    <div className="popup-content" style={{ position: 'relative' }}>
                        <span className="close" onClick={() => setShowPopup(false)}>&times;</span>
                        <h2>Register as Pandit Ji</h2>
                        <form id="panditjiForm" onSubmit={handleFormSubmit} encType="multipart/form-data">
                            <label htmlFor="firstName">First Name:</label>
                            <input type="text" id="firstName" name="firstName" required />

                            <label htmlFor="lastName">Last Name:</label>
                            <input type="text" id="lastName" name="lastName" required />

                            <label htmlFor="qualification">Qualification:</label>
                            <input type="text" id="qualification" name="qualification" required />

                            <label htmlFor="speciality">Speciality:</label>
                            <input type="text" id="speciality" name="speciality" required />

                            <label htmlFor="experience">Experience (in years):</label>
                            <input type="number" id="experience" name="experience" required />

                            <label htmlFor="city">City:</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={city}
                                    onChange={handleCityChange}
                                    onBlur={handleBlur} // Validate on blur
                                    required
                                />
                                {filteredCities.length > 0 && (
                                    <div className="autocomplete-suggestions">
                                        {filteredCities.map((suggestion, index) => (
                                            <div
                                                key={index}
                                                className="autocomplete-suggestion"
                                                onClick={() => handleSuggestionClick(suggestion)}
                                            >
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {showCityError && !validCity && (
                                    <div className="city-error-message" style={{ color: 'red', marginTop: '5px' }}>
                                        Please select a valid city from the suggestions.
                                    </div>
                                )}
                            </div>

                            <label htmlFor="area">Area:</label>
                            <input type="text" id="area" name="area" required />

                            <label htmlFor="mobileNumber">Mobile Number:</label>
                            <input
                                type="tel"
                                id="mobileNumber"
                                maxLength={13} // Allow room for +91 and 10 digits
                                name="mobileNumber"
                                required
                            />
                            {mobileNumberError && <p style={{ color: 'red' }}>{mobileNumberError}</p>}

                            <label htmlFor="fileUpload">Upload Document (JPG, PDF, PNG):</label>
                            <input type="file" id="fileUpload" name="fileUpload" accept=".jpg, .jpeg, .png, .pdf" />

                            <button
                                type="submit"
                                disabled={!validCity} // Disable button if the city is not valid
                                style={{ opacity: validCity ? 1 : 0.5 }} // Optional: style to show disabled state
                            >
                                Register
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegisterPanditji;
