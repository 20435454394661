import React from "react";
import '../styles/Testimonial.css'

const Testimonial = () => {
    return (
        <section className="testimonials">
        <div className="container">
          <h2>Testimonials</h2>
         
        </div>
      </section>
    );
};
export default Testimonial;
