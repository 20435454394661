import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear tokens and user-related data from session storage
        sessionStorage.clear();

        // Navigate to the home page after a short delay
        const timer = setTimeout(() => {
            navigate('/');
        }, 1000); // 1-second delay

        return () => clearTimeout(timer); // Cleanup the timer
    }, [navigate]);

    return (
        <div>
            <h1>Logging out...</h1>
        </div>
    );
};

export default Logout;
