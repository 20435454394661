import React from 'react';
import '../styles/HowWeAreDifferent.css'; // Link to your CSS
import { FaLeaf, FaBook, FaPrayingHands, FaConciergeBell, FaDoorOpen } from 'react-icons/fa'; // Import icons

const HowWeAreDifferent = () => {
  return (
    <div className="pooja-services-container">
      <h1>Why Choose Our Pooja Services?</h1>

      <p className="intro-text">
        Our pooja services ensure that rituals are performed with a focus on purity, tradition, and scientific understanding.
      </p>

      {/* Focus on Scientific Approach */}
      <div className="service-section">
        <FaBook className="service-icon" />
        <div className="service-text">
          <h2>Scientific and Logical Approach</h2>
          <p>
            Our rituals are not only rooted in tradition but also backed by scientific principles that enhance the effectiveness of each ceremony.
          </p>
          <ul>
            <li><strong>Mantra Chanting:</strong> Proven to create positive vibrations that enhance mental clarity and focus.</li>
            <li><strong>Homa (Fire Ritual):</strong> Scientifically shown to purify the environment by reducing airborne bacteria and promoting positive energy.</li>
            <li><strong>Offerings:</strong> Traditional offerings like ghee, water, and herbs have specific physiological and psychological benefits, enhancing well-being.</li>
          </ul>
        </div>
      </div>

      {/* Brief Points on Other Strengths */}
      <div className="service-section">
        <FaLeaf className="service-icon" />
        <div className="service-text">
          <h2>Pure Materials</h2>
          <p>We use authentic, high-quality materials sourced from natural, pesticide-free environments to ensure the purity of rituals.</p>
        </div>
      </div>

      <div className="service-section">
        <FaPrayingHands className="service-icon" />
        <div className="service-text">
          <h2>Experienced Priests</h2>
          <p>Our well-trained priests conduct poojas with expertise, blending tradition with modern understanding for a perfect spiritual experience.</p>
        </div>
      </div>

      <div className="service-section">
        <FaConciergeBell className="service-icon" />
        <div className="service-text">
          <h2>Customized Services</h2>
          <p>We offer a variety of poojas customized to your needs, from weddings to Griha Pravesh, making every ritual personal and meaningful.</p>
        </div>
      </div>

      <div className="service-section">
        <FaDoorOpen className="service-icon" />
        <div className="service-text">
          <h2>Hassle-Free Booking</h2>
          <p>Our seamless booking process and doorstep service ensure you can focus on the spiritual experience without any worries.</p>
        </div>
      </div>

      <p className="final-text">
        Choose us for poojas that blend tradition, purity, and scientific principles, bringing peace and harmony to your home.
      </p>
    </div>
  );
};

export default HowWeAreDifferent;
