const poojaOptions = [
{ type: 'Ganesh Pooja', duration: '1', cost: '₹500' },
{ type: 'Lakshmi Pooja', duration: '2', cost: '₹1000' },
{ type: 'Navagraha Pooja', duration: '3', cost: '₹1500' },
{ type: 'Satyanarayan Pooja', duration: '3', cost: '₹2000' },
{ type: 'Maha Mrityunjaya Jaap', duration: '5', cost: '₹3000' },
{ type: 'Vastu Shanti', duration: '4', cost: '₹2500' },
{ type: 'Durga Pooja', duration: '2', cost: '₹1800' },
{ type: 'Rudrabhishek', duration: '2', cost: '₹1500' },
{ type: 'Graha Pravesh', duration: '3', cost: '₹2500' },
{ type: 'Saraswati Pooja', duration: '1.5', cost: '₹800' },
{ type: 'Kali Pooja', duration: '3', cost: '₹2000' },
{ type: 'Chandi Path', duration: '6', cost: '₹4000' },
{ type: 'Havan', duration: '2', cost: '₹1200' },
{ type: 'Ramayan Path', duration: '24', cost: '₹15000' },
{ type: 'Krishna Janmashtami Pooja', duration: '4', cost: '₹2500' },
{ type: 'Sudarshan Homam', duration: '5', cost: '₹3500' },
{ type: 'Rahu Ketu Pooja', duration: '2', cost: '₹2000' },
{ type: 'Ayush Homam', duration: '3', cost: '₹2500' },
{ type: 'Pitru Dosh Nivaran Pooja', duration: '2.5', cost: '₹3000' },
{ type: 'Kaal Sarp Dosh Pooja', duration: '4', cost: '₹4000' },
{ type: 'Shani Shanti Pooja', duration: '2', cost: '₹1500' },
{ type: 'Mangal Dosh Nivaran Pooja', duration: '2', cost: '₹2000' },
{ type: 'Vivah Muhurat Pooja', duration: '3', cost: '₹3000' },
{ type: 'Naamkaran Sanskar', duration: '1', cost: '₹1000' },
{ type: 'Annaprashan Pooja', duration: '1', cost: '₹1200' },
{ type: 'Sunderkand Path', duration: '5', cost: '₹2500' },
{ type: 'Griha Dosh Nivaran Pooja', duration: '3', cost: '₹3500' },
{ type: 'Lakshmi Kubera Pooja', duration: '4', cost: '₹3000' },
{ type: 'Sankat Nashan Ganesh Pooja', duration: '1.5', cost: '₹1000' },
{ type: 'Nakshatra Shanti Pooja', duration: '2', cost: '₹1500' },
{ type: 'Mool Shanti Pooja', duration: '2', cost: '₹2000' },
{ type: 'Bhagavad Gita Path', duration: '3', cost: '₹1800' },
{ type: 'Shraddha Pooja', duration: '2', cost: '₹2500' },
{ type: 'Chhath Pooja', duration: '6', cost: '₹4000' }
];

export default poojaOptions;
