import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/header.css';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
      const token = sessionStorage.getItem('access_token');
      const storedUsername = sessionStorage.getItem('username');
      if (token) {
          setIsLoggedIn(true);
          setUsername(storedUsername);
      }
  }, []);

  const handleLogout = () => {
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('username');
      setIsLoggedIn(false);
      navigate('/');
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">Findyourpandit</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/home">Home</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </a>
              <ul className="dropdown-menu">
                <li><Link to="/findmypanditji" className="dropdown-item">Pandit Ji Service</Link></li>
              </ul>
            </li>
          </ul>
    <div class="running-text">
            <div class="running-text-content">
              For special queries, <a href="https://wa.me/918247751974" target="_blank" rel="noopener noreferrer">WhatsApp us at 8245571974</a>
            </div>
          </div>
          <div className="navbar-nav ml-auto mb-2 mb-lg-0">
            <Link className="nav-link" to="/about">About</Link>
            <Link className="nav-link" to="/contact">Contact Us</Link>
          </div>
          <nav className="nav-links">
            {isLoggedIn ? (
              <>
                <span className="username">Hello, {username}</span>
                <button className="logout-button" onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</button>
              </>
            ) : (
              <>
                <button className="nav-button" onClick={() => navigate('/login')}><FontAwesomeIcon icon={faSignInAlt} /> Login</button>
                <button className="nav-button" onClick={() => navigate('/register')}><FontAwesomeIcon icon={faUserPlus} /> Sign Up</button>
              </>
            )}
          </nav>
        </div>
      </div>
    </nav>
  );
};

export default Header;
